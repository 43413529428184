<template>
  <client-only>
    <div v-if="notifications?.length > 0">
      <Vue3Marquee class="lb-marquee" clone :duration="18">
      <span
          v-for="(i, index) in notifications"
          :key="index"
          class="px-5 text-sm font-medium"
          :class="{ word: true, odd: index % 2 === 0, even: index % 2 === 1 }"
      >
        {{i.title}}
      </span>
      </Vue3Marquee>
    </div>
  </client-only>
</template>

<script setup>
const headers = useRequestHeaders(['cookie'])
const { data : notifications, error, refresh } = useLazyAsyncData('notifications', () => $fetch('/api/app/notifications', {
  headers: headers,
}))
</script>

<style scoped>

</style>